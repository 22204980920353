<template>
    <div class="register-page">
        <div class="register-box">
            <div class="card card-outline card-primary">
                <div class="card-body p-5">
                    <div class="text-center font-size-30 mb-5">
                        <img src="images/logo-acc-transparent-50w.png" /> PoS
                    </div>

                    <p class="login-box-msg">Signup for an account</p>

                    <ValidationObserver v-slot="{ invalid }">
                        <form @submit="saveForm">
                            <div class="input-group mb-3">
                                <input type="text" class="form-control" id="name" placeholder="Full name" v-model="formData.name" required>
                                <div class="input-group-append">
                                    <div class="input-group-text">
                                        <span class="fas fa-user"></span>
                                    </div>
                                </div>
                            </div>
                            <ValidationProvider v-slot="{ errors }" rules="required|email">
                                <div class="input-group mb-3">
                                    <input type="email" class="form-control" id="email" placeholder="Email" v-model="formData.email" required>
                                    <div class="input-group-append">
                                        <div class="input-group-text">
                                            <span class="fas fa-envelope"></span>
                                        </div>
                                    </div>
                                </div>
                                <div>{{ errors[0] }}</div>
                            </ValidationProvider>
                            <ValidationProvider v-slot="{ errors }" :rules="'required|min:6'" vid="password">
                                <div class="input-group mb-3">
                                    <input type="password" class="form-control" id="password" placeholder="Password" v-model="formData.password" required>
                                    <div class="input-group-append">
                                        <div class="input-group-text">
                                            <span class="fas fa-lock"></span>
                                        </div>
                                    </div>
                                </div>
                                <div>{{ errors[0] }}</div>
                            </ValidationProvider>
                            <ValidationProvider v-slot="{ errors }" :rules="'required|confirmed:password'">
                                <div class="input-group mb-3">
                                    <input type="password" class="form-control" id="repeat-password" placeholder="Confirm password" v-model="formData.password_confirmation" required>
                                    <div class="input-group-append">
                                        <div class="input-group-text">
                                            <span class="fas fa-lock"></span>
                                        </div>
                                    </div>
                                </div>
                                <div>{{ errors[0] }}</div>
                            </ValidationProvider>

                            <div class="icheck-primary">
                                <input type="checkbox" id="agreeTerms" name="terms" value="agree" v-model="formData.terms_agree">
                                <label for="agreeTerms" class="text-sm text-wrap ml-2">
                                    I agree to <a :href="$globalSettings.terms_url">Terms</a> and <a :href="$globalSettings.privacy_url">Privacy</a>
                                </label>
                            </div>
                            <div><button type="submit" class="btn btn-primary btn-block mt-3" :disabled="invalid"><i class="fas fa-user-plus mr-1"></i> Signup</button></div>
                        </form>
                    </ValidationObserver>

                    <div class="mt-5"><router-link :to="{ name: 'Login' }" class="text-center"><i class="fas fa-user-lock mr-1"></i> Already have an account?</router-link></div>
                </div>
                <!-- /.form-box -->
            </div><!-- /.card -->
        </div>
        <!-- /.register-box -->
    </div>
</template>

<script>
    import Vue from 'vue'

    import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
    import { confirmed, required, min, email } from 'vee-validate/dist/rules';
    import {UserService} from "@/services/user-service";

    extend('required', {
        ...required,
        message: 'This field is required'
    });
    extend('min', {
        ...min,
        message: 'This field must be {length} characters'
    });
    extend('email', {
        ...email,
        message: 'This field must be an email'
    });
    extend('confirmed', {
        ...confirmed,
        message: 'The passwords must match'
    });

    let userService = new UserService();
    export default {
        name: "Signup",
        data: function(){
            return {
                formData: {
                    name: null,
                    email: null,
                    password: null,
                    password_confirmation: null,
                    terms_agree: false,
                },
            }
        },
        components: {
            ValidationObserver, ValidationProvider
        },
        methods: {
            saveForm: async function (e) {
                e.preventDefault();

                let loader = this.$loading.show();
                userService.formDataUser = this.formData;
                let response = await userService.signup();
                if(response.isSuccess){
                    Vue.$toast.open({ message: response.message, type: response.status});
                    let userInitResponse = await userService.userInit();
                    loader.hide();
                    if(userInitResponse.isSuccess){
                        await this.$router.push({name: 'App.Dashboard'});
                    }else{
                        Vue.$toast.open({ message: 'You are not authorized!',  type: 'error'});
                    }
                }else{
                    loader.hide();
                }
            }
        }
    }
</script>

<style scoped>

</style>